/* eslint react/prop-types: 0 */
import React from 'react';
import MuxPlayer from '@mux/mux-player-react';
import type MuxPlayerElement from '@mux/mux-player';
import { H1MuxPlayerInnerProps } from './types';

export const DefaultH1MuxPlayer = React.forwardRef<MuxPlayerElement, H1MuxPlayerInnerProps>(({
  playbackId,
  metadata,
  muxEnvKey,
  placeholder,
  muxDebug = false,
  autoPlay = false,
  loop = false,
  muted = false,
  defaultShowCaptions = false,
  thumbnailTime = 0,
  ...rest
}, ref) => {
  return (
      <MuxPlayer
        {...rest}
        // @ts-ignore
        ref={ref}
        playbackId={playbackId}
        envKey={muxEnvKey}
        muted={muted}
        debug={muxDebug}
        autoPlay={autoPlay}
        defaultHiddenCaptions={!defaultShowCaptions}
        metadata={metadata}
        loop={loop}
        thumbnailTime={thumbnailTime}
        placeholder={placeholder}
        streamType="on-demand"
        primaryColor="white"
        secondaryColor="rgb(0 0 0 / 40%)"
      />
  );
});
