/* eslint react/prop-types: 0 */
import React, { useCallback, useRef } from 'react';
import type MuxPlayerElement from '@mux/mux-player';
import { H1MuxPlayer, H1MuxPlayerProps } from "../h1-mux-player";
import { SegmentTrackProps } from '../segment-context';
import { useSegment } from "../segment-hook";

export type H1PlayerAnalyticsProps = H1MuxPlayerProps & {
  /**
   * Enrich segment tracking with data
   */
  trackEventsProps?: Record<string, any>;

  /**
   * When set `true` events aren't being sent
   */
  dryRun?: boolean;

  /**
   * When set `true` logging on console
   */
  debug?: boolean;

  /**
   * Elternative way of using trak, it will be used instead of useSegment to track events
   */
  trackEvent?: (segmentTrackProps: SegmentTrackProps) => void;

};

declare type EventsInterface = {
  [key in Event as string]: string;
};

// Mapping https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events to analytics events
const mappedEventsToState: EventsInterface = {
  canplay: 'player:player_videoLoaded',
  playing: 'player:player_play',
  pause: 'player:player_pause',
  ended: 'player:player_videoFinished',
  volumechange: 'player:player_mute',
};

export const H1PlayerAnalytics = React.forwardRef<MuxPlayerElement, H1PlayerAnalyticsProps>((props, ref) => {
  const {
    trackEventsProps, 
    trackEvent,
    debug = false, 
    dryRun = false
  } = props;
  
  const segment = useSegment({
    dryRun,
  });
  const track = trackEvent || segment?.track;

  const sendAnalytics = (eventName: string) => {
    if (debug) {
      console.log(
        `will send ${mappedEventsToState[eventName]} to analytics on player ${eventName}, with enriched data: ${trackEventsProps}`,
      );
    }
    if(track){
      track({ name: mappedEventsToState[eventName], properties: trackEventsProps });
    } else {
      console.warn("No Track function for event. It won't be tracked");
    }
  };

  const subscriptionHolder = useRef<Record<keyof EventsInterface, (e:Event) => void>>(
    {}); // Avoid re-rendering on changed obj

  const handleEventsSubscription = (event: Event, node: MuxPlayerElement, analyticsEvent: keyof EventsInterface) => {
    if (debug) {
      console.log(`Recived ${event.type} event, with details:`, { event });
    }

    switch (event.type) {
      case 'pause':
        if (node.hasPlayed) sendAnalytics(analyticsEvent as string);
        break;
      case 'volumechange':
        if (!node.volume) sendAnalytics(analyticsEvent as string);
        break;
      default:
        sendAnalytics(analyticsEvent as string);
        break;
    }
  };

  const subscribeMediaEvents = useCallback((node): void => {
    Object.keys(mappedEventsToState).forEach( analyticsEvent => {
      subscriptionHolder.current[analyticsEvent] = (e:Event) => handleEventsSubscription(e, node, analyticsEvent);
      if(typeof node === 'object' && node !== null && 'addEventListener' in node){
        node.addEventListener(analyticsEvent, subscriptionHolder.current[analyticsEvent], false);
      }
    });
  },[ subscriptionHolder.current ]);

  const unSubscribeMediaEvents = useCallback((node): void => {
    Object.keys(subscriptionHolder.current).forEach( analyticsEvent => {
      if (subscriptionHolder.current[analyticsEvent] && typeof subscriptionHolder.current[analyticsEvent] === 'function') {
        node.removeEventListener(analyticsEvent, subscriptionHolder.current[analyticsEvent]);
        if (debug) {
          console.log(`${analyticsEvent} is unsbscribed`, subscriptionHolder);
        }
        delete subscriptionHolder.current[analyticsEvent];
      }
    })
  }, []);

  return (
    <H1MuxPlayer
      {...props}
      ref={ref}
      onMount={subscribeMediaEvents}
      onUnmount={unSubscribeMediaEvents}
    />
  );
});
