import React, { ReactNode } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { PrependSend, SegmentContextContext } from './segment-context-context';

export type SegmentContextProviderProps = {
  /**
   * Instance of segment analytics
   */
  writeKey: string;

  /**
   * Function that will triger after events sent to ssegment
   */
  prependSend?: PrependSend;

  /**
   * children to be rendered within this theme.
   */
  children: ReactNode;
};

export function SegmentContextProvider({
  writeKey,
  prependSend,
  children,
}: SegmentContextProviderProps) {
  const analytics = React.useMemo(
    () => AnalyticsBrowser.load({ writeKey }),
    [writeKey],
  );
  return (
    <SegmentContextContext.Provider value={{ analytics, prependSend }}>
      {children}
    </SegmentContextContext.Provider>
  );
}
