export type H1MuxPlayerShapes = 'portrait' | 'circle' | 'landscape';
type ConfigType = {
  ratio: string;
  controls: boolean;
};

export const configs: Record<H1MuxPlayerShapes, ConfigType> = {
  portrait: {
    ratio: '9/16',
    controls: true,
  },
  circle: {
    ratio: '1/1',
    controls: false,
  },
  landscape: {
    ratio: '16/9',
    controls: true,
  },
};
